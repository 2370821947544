import { Component, OnInit } from '@angular/core';
import { AllowPrevTransEditService } from '../allowprevtransedit/allowprevtransedit.service';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { exit } from 'process';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'allowprevtransedit',
  templateUrl: `allowprevtransedit.component.html`,  
})
export class AllowPrevTransEditComponent implements OnInit {
  //@ViewChild('noofinstallments') inputName;
  pipe = new DatePipe('en-US');
  now = Date.now();
  admno: any;
  StudentShift: any;
  GLBappFormStarEnd: string = "";
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  Admdate: any;
  Efdate: any;
  Student: any = [];
  StudentDD: any;
  Fathermail: string = "";
  Mobile2: string = "";
  Mobile1: string = "";
  Father: string = "";
  Mother: string = "";
  SName: string = "";
  RollNo: string = "";
  statusCode: number = 0;
  
  public Courses: any;
  Category: any[];
  CheckBool: boolean;
  checkedM: string;
  checkedF: string;
  checkedO: string;
  checkedNew: string;
  checkedShiftM: string;
  checkedShiftE: string;
  checkedOld: string;
  UserForm: FormGroup;
  STUDADMNO: string = "";
  STUDADMNONEW: string = "";
  GLBappFormType: string = "";
  GLBappUserCode: string = "";
  UserName: any;
  date: string = '';
  GLBappFormName: string = "";
  isChecked: boolean = false;  
  IPAddress: string = "";
  YCode14: number = 0;
  public getFyYear: any = [];
  StatusCheck: string;
 // YearCodeStr: string = '2023-2024';
  constructor(private studService: AllowPrevTransEditService, private fb: FormBuilder, private router: Router) {
    this.todayDate = this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd');
    this.IPAddress = sessionStorage.getItem('ipaddress');    
    this.GLBappFormName = "Allow/Dis-Allow Prev. Year Delete/Edit Transactions";
    this.StatusCheck = '';
    this.statusCode = 0;
  }

  //onChangeDate(event: any) {
  //  //this.pipe.transform(this.now, 'yyyy-MM-dd');
  //  sessionStorage.setItem('currentdate', this.pipe.transform(event.target.value, 'yyyy-MM-dd'));    
  //}

  ngOnInit() {
    //debugger;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }

    this.getFyYear = [];
    this.studService.GetFYDetCheck(14).subscribe((getFydetails: any) => {
      this.getFyYear = getFydetails;
      //sessionStorage.setItem('YCode', this.getFyYear[0].yCode);
      //sessionStorage.setItem('YearCode', this.getFyYear[0].yearCode);
      //sessionStorage.setItem('FYYear', this.getFyYear[0].startEnd);
      //sessionStorage.setItem('FileName2', this.getFyYear[0].fileName2);
      this.StatusCheck = "Allowed";
      this.statusCode = 0;
      if (this.getFyYear[0].yesTran == 9) {
        this.StatusCheck = "Not Allowed";
        this.statusCode = 9;
      }      
    }, (error) => {
      console.log(error);
    });
  }

  UpdateAllowPrevTrans(YCode: number, YearCodeStr: string) {    
    Swal.fire({
      //position: 'top-end',        
      html: "<strong><H3>Are sure you want to Allow Edit/Delete Prev. Year Transactions?</H3></strong>",
      showCancelButton: true,
      icon: 'question',
      //iconHtml: '<img src="https://picsum.photos/100/100">',
      width: 400,
      padding: 50,
      //className: "green-bg",
      confirmButtonColor: "#CD0320",
      confirmButtonText: 'Delete',
      cancelButtonColor: "#DB6138",
      cancelButtonText: 'Abort'
    }).then((result: { value: any; }) => {
      if (result.value) {
        this.studService.AllowDisAllowPrevTrans(YCode, YearCodeStr).subscribe((response: any) => {
          Swal.fire({
            html: "<strong>Record has been Updated</strong>",
            icon: 'success',
            showConfirmButton: true,
            confirmButtonColor: "#338921",
            confirmButtonText: 'OK'
            //background: '#fff url(//bit.ly/1Nqn9HU)',
            //timer: 300000
          }).then((result) => {
            if (result.value) {
              sessionStorage.setItem('usercode', '0');
              sessionStorage.setItem('uname', '');
              sessionStorage.setItem('currentdate', '');
              sessionStorage.setItem('instname', '');
              sessionStorage.setItem('formname', '');
              sessionStorage.setItem('YCode', '0');
              sessionStorage.setItem('YearCode', '0');
              sessionStorage.setItem('ClientId', '0');
              sessionStorage.setItem('iShiftId', '0');
              sessionStorage.setItem('ShiftStr', '');
              sessionStorage.clear();
              this.router.navigate(['/loginuser'])
                .then(() => {
                  window.location.reload();
                });
              return;
              //this.router.navigate(['/allowprevtransedit'])
              //  .then(() => {
              //    window.location.reload();
              //  });
              ////this.getFeeCollectionDWise();
            }
          })
          //this.getFeeCollectionDWise();
          //window.alert("Record has been deleted");
        }, (error) => {
          console.log(error);
        });
      }
    });

    //if (window.confirm('Are sure you want to delete this Transaction?')) {      
    //  this.service.DeleteStudentFeeTran(RNo, YearCode, TrnNo).subscribe((response: any) => {
    //    this.getFeeCollectionDWise();
    //    window.alert("Record has been deleted");
    //  }, (error) => {
    //    console.log(error);
    //  });
    //}
  }


  //onFormSubmit() {
  //  //Admno: 431915
  //  debugger;
  //  this.CheckBool = true;      
  //  this.studService.AllowDisAllowPrevTrans(this.pipe.transform(sessionStorage.getItem('currentdate'), 'yyyy-MM-dd')).subscribe((response) => {      
  //        //window.alert('New Date Updated Successfully');
  //        Swal.fire({
  //          html: "<strong>Updated Successfully.</strong>",
  //          icon: 'info',
  //          showConfirmButton: true,
  //          confirmButtonColor: "#338921",
  //          confirmButtonText: 'OK'
  //        }).then((result) => {
  //          if (result.value) {
  //            this.router.navigate(['/student-master'])
  //              .then(() => {
  //                window.location.reload();
  //              });
  //            this.ngOnInit();
  //          }
  //        })          
  //    }, (error) => {
  //      console.log(error);
  //    });
  //  //}
  //}

  public SetGetNullValueStr(parametername: string) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return "NULL";
    }
  }

  public SetGetNullValueInt(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return Number(0);
    }
  }

  public SetGetNullValueDate(parametername: any) {
    if (typeof parametername != 'undefined' && parametername) {
      return parametername;
    } else {
      return '1900-01-01';
    }
  }  
}
