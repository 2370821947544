import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
/*import { FormBuilder } from '@angular/forms';*/


@Injectable({
  providedIn: 'root'
})
export class TransFeesService {
  userData: string[][];
  //formData: Student = new Student();
  readonly baseURL = sessionStorage.getItem('NbaseURLs');
  baseURL_2 = 'https://localhost:44386/api';
  //constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    }),
  };
  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    sessionStorage.setItem('NbaseURLs', 'https://www.eduinst.in/api/NStud');
    //sessionStorage.setItem('NbaseURLs', 'https://localhost:5001/api/NStud');
    //this.baseURL = sessionStorage.getItem('NbaseURLs');
  }

  GetAllStudent() {
    return this.http.get(this.baseURL + '/GetAllStudents');
  }

  getData() {
    return sessionStorage.getItem('uname');
  }

  GetLastTranDateFive(YearCode: number, ShiftId: number) {
    return this.http.get(this.baseURL + '/GetLastTranDateFive?YearCode=' + YearCode + '&&ShiftId=' + ShiftId)
      .pipe(retry(1), catchError(this.handleError));
  }

  UpdateTemps2(AID: number, HeadCode: number, HeadAmt: number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/UpdateTemps2?AID=' + AID + '&&HeadCode=' + HeadCode + '&&HeadAmt=' + HeadAmt);
  }
  UpdateTempsW2(AID: number, HeadCode: number, HeadAmt: number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/UpdateTempsW2?AID=' + AID + '&&HeadCode=' + HeadCode + '&&HeadAmt=' + HeadAmt);
  }

  UpdateTempsW3(AID: number, HeadCode: number, HeadAmt: number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/UpdateTemps3?AID=' + AID + '&&HeadCode=' + HeadCode + '&&HeadAmt=' + HeadAmt);
  }

  UpdateTempsWD3(AID: number, HeadCode: number, HeadAmt: number): Observable<any> {
    debugger;
    return this.http.get<any>(this.baseURL + '/UpdateTempsW3?AID=' + AID + '&&HeadCode=' + HeadCode + '&&HeadAmt=' + HeadAmt);
  }

  GetStudentFeeDETN(admno: string, todaydate: string, YCode: Number, YearCode: Number): Promise<any> {
    debugger;
    //console.log(this.baseURL + '/GetStudentFeeDet?Admno=' + admno + '&&UptoDate=' + todaydate);
    return this.http.get<any>(this.baseURL + '/GetStudentFeeDetN?Admno=' + admno.toString() + '&&UptoDate=' + todaydate.toString() + '&&YCode=' + YCode + '&&YearCode=' + YearCode + '&&UserCode=' + parseInt(sessionStorage.getItem('usercode')) ).toPromise();
      /*.pipe(retry(1), catchError(this.handleError));*/
  }

  ViewGetStudinstallmentDetails(admno: string, YearCode: Number): Observable<any> {
    debugger;
    //string Admno, Int64 YearCode, Int64 ShiftId
    //console.log(this.baseURL + '/ViewGetStudinstallmentDetails?Admno=' + admno + '&&YearCode=' + yearcode + '&&ShiftId=' + shiftid);
    return this.http.get<any>(this.baseURL + '/ViewGetStudinstallmentDetails?Admno=' + admno + '&&YearCode=' + YearCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetStudentFeeDETAdmnoN(admno: any, YearCode: Number) {
    debugger;
    return this.http.get(this.baseURL + '/GetStudentFeeDetAdmnoN?Admno=' + admno.toString() + '&&YearCode=' + YearCode);
      /*.pipe(retry(1), catchError(this.handleError));*/
  }

  GetPDCTranDet(admno: any, YearCode: Number) {
    debugger;
    return this.http.get(this.baseURL + '/GetPDCTranDet?Admno=' + admno.toString() + '&&YearCode=' + YearCode);
    /*.pipe(retry(1), catchError(this.handleError));*/
  }

  GetStudentFeeDETAdmnoDues(admno: any, YearCode: Number): Observable<any> {
    return this.http.get<any>(this.baseURL + '/GetStudentFeeDetAdmnoDues?Admno=' + admno + '&&YearCode=' + YearCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  GetStudentFeeDETAdmnoH(admno: any): Observable<any> {
    return this.http.get<any>(this.baseURL + '/GetStudentFeeDetAdmnoH?Admno=' + admno)
      .pipe(retry(1), catchError(this.handleError));
  }

  OGetStudentFeeDetAdmno(admno: any): Observable<any> {
    return this.http.get<any>(this.baseURL + '/OGetStudentFeeDetAdmno')
      .pipe(retry(1), catchError(this.handleError));
  }

  GetLastTranAdded(): Observable<any> {
    return this.http.get<any>(this.baseURL + '/GetLastStatus')
      .pipe(retry(1), catchError(this.handleError));
  }

  AddStduentTranFeesDet1(UserForm: any): Observable<any> {
    debugger;
    //return this.http.post<any>(this.baseURL + '/AddStduentTranFeesDet1888888', UserForm);
    return this.http.post<any>(this.baseURL + '/AddStduentTranFeesDet1', UserForm);
  }

  AddStduentTranFeesDet(_formdata: any[], userInput: any[], userInputW: any[], _formtype: string, _usercode: string): Observable<any> {
    return this.http.get<any>(this.baseURL + '/AddStduentTranFeesDet?userInput=' + userInput.toString())
  }

  ViewPDCTranDet(TodayDate: Date, YearCode: number): Observable<any> {
    return this.http.get<any>(this.baseURL + '/ViewPDCTranDet?TodayDate=' + TodayDate + '&&YearCode=' + YearCode)
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }  
}
